import React from 'react';
import {Link} from "react-router-dom"
import { Col, Container, Row } from 'react-bootstrap';
import TopNav from "../../components/TopNav/TopNav"
import Banner from "../../components/Banner/Banner"
import Footer from "../../components/Footer/Footer"

export default function Portfolio() {
  return (
    <div className="Portfolio">
     <TopNav />
     <Banner />
     <Container className='pt-5 pb-5'>
      {/* <MetaData type="website"/> */}
        <h1>Portfolio</h1>
        <p>Coming Soon - See <a href="https://www.facebook.com/arttraphotography" target="_blank" rel="noopener noreferrer">Facebook</a> while we finish updating our site!</p>
        </Container>
        <Footer />
    </div>
  );
}