import React, { useEffect, useState, useRef } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Container, Nav, Navbar } from 'react-bootstrap';
import NavLink from './NavLink';

import ArtTraLogo from '../../assets/images/arttra_logo.png';
import './TopNav.css';

function NavBar() {

  return (
    <Navbar expand="md" fixed="top" setExpanded="false" className="NavBar">

      <Container>

        <Navbar.Brand href="/">
          <img src={ArtTraLogo} alt="ArtTra Photography" />
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <NavLink to="/" display="Home" />
            <NavLink to="/portfolio" display="Portfolio" />
            <NavLink to="/about" display="About" />
            {/*<NavLink to="/blog" display="Blog"/>*/}
            <NavLink to="/contact" display="Contact" />
          </Nav>
        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
}

export default NavBar;
