import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TopNav from "../../components/TopNav/TopNav"
import Banner from "../../components/Banner/Banner"
import Footer from "../../components/Footer/Footer"
import tracy from "../../assets/images/tracy.jpg"

export default function About() {
    return (
        <div className="About">
            <TopNav />
            <Banner />
            <Container className="pt-5 pb-5">
                {/* <MetaData type="website"/> */}
                <h1>About Tracy</h1>
                <Row>
                    <Col>
                        <p>Thank you for your interest in ArtTra Photography! My name is Tracy VanRider. I am a photographer who believes that a photograph is one of life’s best souvenirs (souvenir is French for memory). From family, to focus events, to fine arts, my passion is that camera click that captures the moments of your life.</p>

                        <p>ArtTra Photography provides creative, artistic, and enthusiastic photography services to meet your individual needs. It is my mission to facilitate the best possible photographic experience for your engagements, weddings, family and pet portraits, birthdays, and special occasions.</p>

                        <p>I am a native of Chattanooga, TN,  and while pursuing my Associates Degree in Business Management at Chattanooga State Technical Community College, I discovered my interest in photography. My completed college photography courses are Black and White Darkroom I and II, Color, and Digital Photography. I also achieved a Certificate of Graduation from New York Institute of Photography for an online professional photography course.</p>

                        <p>I have been a freelance photographer since 2005. One of my fine art prints was displayed locally at the Electric Power Board in 2006, which was sponsored by Chattanooga State Technical Community College. The Chattanooga Area Convention and Visitors Bureau purchased three of my fine arts prints of local scenes for their advertising.  Please visit my galleries in order to view examples of my work.</p>

                        <p>I look forward to collaborating with you as together we endeavor to achieve the vision of creating some of life’s best souvenirs.</p>
                    </Col>


                    <Col md='4'>
                        <img src={tracy}/>
                    </Col>
                </Row>

            </Container>
            <Footer />
        </div>

    );
}