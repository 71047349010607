import React, { useEffect, useState, useRef } from 'react';

import { Link, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import ArtTraLogo from '../../assets/images/arttra-logo2.png';
import FacebookLogo from '../../assets/images/facebook.png';
import './Footer.css';

function Footer() {

    return (

        <footer className='pt-2 pb-2'>
            <Container>
                <Row>
                    <Col md='4'>
                    <img src={ArtTraLogo} style={{ height: '50px' }} />
                <br />
                
                    </Col>
                    <Col md='4'>
                    </Col>
                    <Col md='4' className='text-end'>
                    
                <a href="https://www.facebook.com/arttraphotography" target="_blank" rel="noopener noreferrer">
                    <img src={FacebookLogo} alt="facebook" />

                </a>
                    </Col>
                </Row>
                <Row>
                    <Col md='6' className='text-left'>Copyright 2023 ArtTra Photography</Col>
                    <Col md='6'><p className='text-end'>423-567-5ART (5278)</p></Col>
                </Row>
                
                

            </Container>
        </footer>

    );
}

export default Footer;