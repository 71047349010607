import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {Nav} from "react-bootstrap";
import PropTypes from "prop-types";

function NavLink({to, display}) {
  const pathname = useLocation()?.pathname;

  return <Nav.Link as={Link} to={to} active={pathname === `${to}`}>{display}</Nav.Link>
}

NavLink.propTypes = {
  to: PropTypes.string,
  display: PropTypes.string,
};

export default NavLink;