import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TopNav from "../../components/TopNav/TopNav"
import Banner from "../../components/Banner/Banner"
import Footer from "../../components/Footer/Footer"

export default function Contact() {
  return (
    <div className="Contact">
     <TopNav />
     <Banner />
     <Container className='pt-5 mb-5'>
      {/* <MetaData type="website"/> */}
        <h1>Contact</h1>
        <p>I’d love you work with you! If you are interested in hiring me to capture your life’s moments, please text or call me at 423-567-5ART (5278) and I’ll get back in touch with you as soon as I can!</p>
</Container>
<Footer />
    </div>
    
  );
}