import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';

import Home from './pages/Home/Home';
import Portfolio from './pages/Portfolio/Portfolio';
import About from './pages/about/About';
import Contact from './pages/Contact/Contact';

import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';

export default function App() {

  return (
    <div className="app">
     {/* <NavBar/> */}
      <div className="main">
        <Switch>
          <Route exact path="/portfolio" component={Portfolio}/>
          <Route exact path="/about" component={About}/>
          <Route exact path="/contact" component={Contact}/>
          <Route component={Home}/>
        </Switch>
      </div>

    {/*  <Footer/> */}

    </div>
  )
}