import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { withRouter, Link } from "react-router-dom"

import TopNav from "../../components/TopNav/TopNav"
import Banner from "../../components/Banner/Banner"
import Footer from "../../components/Footer/Footer"
import tracy from "../../assets/images/tracy.jpg"

export default function Home() {
  return (
    <div className="Home">
      {/* <MetaData type="website"/> */}
      <TopNav />
      <Banner />
      <Container className='pt-5 pb-5'>
        <Row>
          <Col md='4'>
            <h1 style={{ fontSize: '5rem', lineHeight: '4.5rem' }}><span className="text-muted">Capturing</span><br />
              <span className='ml-3'>Moments</span></h1>
          </Col>
          <Col className='d-flex align-items-center'>
            <p>We believe that photographs are some of lifes best souvenirs. It is our goal to capture those memories in the moment they are made.
              specializing in candid, natural light photography it is our mission to facilitate the best possible photographic experience for your engagements, weddings, family and pet portraits, birthdays, and special occasions in and around Chattanooga TN.
            </p>
          </Col>
        </Row>
        <hr />
        <Row className='pt-5'>
          <Col>
            <p>My name is Tracy VanRider. I am a photographer who believes that a photograph is one of life’s best souvenirs (souvenir is French for memory). From family, to focus events, to fine arts, my passion is that camera click that captures the moments of your life.</p>
            <p><Button as={Link} to={'/about'}>Read More</Button></p>
            
          </Col>
          <Col md='4'>
            <img src={tracy} style={{ height: '150px' }} />
          </Col>
        </Row>

      </Container>
      <Footer />
    </div>
  );
}
