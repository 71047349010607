import React, {useEffect, useState, useRef} from 'react';
import { Carousel } from 'react-bootstrap';
import Holder from 'react-holder';

import BannerOne from '../../assets/images/Sam_Drew_Wedding_ArtTra-1439.jpg';
import './Banner.css';

function Banner() {
  
  return (
    <Carousel>
  <Carousel.Item>
  <div className="banner-image banner-one" />
  </Carousel.Item>
  <Carousel.Item>
  <div className="banner-image banner-two" />
  </Carousel.Item>
  <Carousel.Item>
  <div className="banner-image banner-three" />
  </Carousel.Item>
  <Carousel.Item>
  <div className="banner-image banner-four" />
  </Carousel.Item>
  
</Carousel>
  );
}

export default Banner;
